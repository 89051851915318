<template>
  <div id="inspire">
    <div>
      <div class="bg-gray2 w-full px-2 md:px-0 h-screen flex items-center justify-center" v-if="ShowErr === '' && firstLoading">
        <div class="bg-white border border-gray1 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <!-- <p class="text-7xl md:text-7xl lg:text-7xl font-bold tracking-wider text-green-400"><i class="fa-solid fa-check fa-fade"></i></p> -->
            <div class="text-center justify-center flex">
              <img v-if="!showErrorImg" height="80px" width="80px" src="@/assets/images/png.png" alt="clearInput">
              <img v-if="showErrorImg" height="80px" width="80px" src="@/assets/images/cancel.png" alt="clearInput">
            </div>
            <p class="text-gray-500 mt-4 py-2 border-y-2 text-center heading-4" v-if="!showErrorImg">Your Email verified successfully, Please Log-In again.</p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center heading-4" v-if="showErrorImg">Your session Token has expired</p>
            <div class="py-2 pt-6" v-if="!showErrorImg">
              <button type="button" class="bg-primary text-white hover:bg-opacity-75  shadow  hover:shadow-xl global_btn rounded-xl cursor-pointer py-2 px-12 cta-text font-semibold" @click="checkApprover()">
                  Ok
                </button>
              <!-- <Button class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="''" @buttonAction="()"/> -->
            </div>
        </div>
      </div>
      <div v-if="ShowErr !== '' && firstLoading">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-red-400"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">{{ ShowErr }}</p>
          </div>
        </div>
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import {removeCookies} from '@/utils/cookies'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
// import Button from '@/View/components/globalButton.vue'
export default {
  components: {
    Loader,
    snakBar,
    // Button,
  },
  data () {
    return {
      showErrorImg: false,
      ShowErr: '',
      firstLoading: false,
    }
  },
  beforeMount () {},
  created () {},
  mounted () {
    this.checkTokenValue()
  },
  methods: {
    checkTokenValue () {
      console.log('emailVerify', this.$route.query)
      if (this.$route.query.tkn) {
        MyJobApp.EmailVarifyLogin(
          this.$route.query.tkn,
          (response) => {
            this.firstLoading = true
            this.showErrorImg = false
            console.log('response -------->', response)
          },
          (error) => {
            if (error.message === 'Invalid Token') {
              this.ShowErr = 'Please Check Your Token and Try Again Later'
              this.firstLoading = true
              this.showErrorImg = false
            } else if (error.message === 'session expired') {
              this.ShowErr = ''
              this.showErrorImg = true
              this.firstLoading = true
            } else {
              this.ShowErr = error.message
              this.firstLoading = true
              this.showErrorImg = false
            }
          }
        );
      } else {
        this.showErrorImg = false
        this.ShowErr = 'Invalid Request'
        this.firstLoading = true
      }
    },
    checkApprover() {
      removeCookies('jobAuthToken')
      localStorage.clear()
      window.location.replace('login');
      // this.$router.push({name: 'login'}).catch(()=>{});
    }
  },
  beforeDestroy () {},
  computed: {
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.backgroundWindow {
  background: url('https://workapp-public-download.s3.ap-south-1.amazonaws.com/assets/signup-in-background.jpg');
  background-size: cover;
  background-position: center
}
.card_design {
  max-width: 600px;
}

</style>
